@import 'colors';
@import 'media';
@import 'include_media';
@import 'mixin';
@import 'z_index';
@import 'variable';

.body-scroll-lock {
  overflow: hidden;
}

.float-right {
  float: right;
}

.border-shadow {
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba($black, .05), 0 2px 4px 0 rgba($black, .15);
}

button:focus {
  outline: none;
}
