$information-mobile: 'screen and (max-width:767px)';
$information-tablet: 'screen and (max-width:991px)';
$information-desktop: 'screen and (max-width:1199px)';
$information-only-tablet: 'screen and (max-width:991px) and (min-width:768px)';
$information-only-desktop: 'screen and (max-width:1199px) and (min-width:992px)';
$information-desktop-tablet: 'screen and (max-width:1199px) and (min-width:768px)';
$information-above-desktop: 'screen and (min-width:1200px)';
$information-above-tablet: 'screen and (min-width:992px)';
$information-above-mobile: 'screen and (min-width:768px)';
$information-event-fullscreen: 'screen and (max-width:1242px)';
$information-event-desktop: 'screen and (min-width:641px)';
$information-event-mobile: 'screen and (max-width:640px)';

// Utilities
.show-on-mobile {
  @media only #{ $information-above-mobile } {
    display: none !important;
  }
}

.show-on-tablet {
  @media only #{ $information-above-tablet } {
    display: none !important;
  }
}

.show-on-desktop {
  @media only #{ $information-above-desktop } {
    display: none !important;
  }
}

.show-event-on-mobile {
  @media only #{ $information-event-desktop } {
    display: none !important;
  }
}

.hide-on-mobile {
  @media only #{ $information-mobile } {
    display: none !important;
  }
}

.hide-on-tablet {
  @media only #{ $information-tablet } {
    display: none !important;
  }
}

.hide-on-desktop {
  @media only #{ $information-desktop } {
    display: none !important;
  }
}

.hide-event-on-mobile {
  @media only #{ $information-event-mobile } {
    display: none !important;
  }
}

.hidden-desktop-up {
  display: none !important;

  @media #{$information-tablet} {
    display: block !important;
  }
}

.hidden-tablet-down {
  display: block !important;

  @media #{$information-tablet} {
    display: none !important;
  }
}

.stop-scrolling-under-tablet {
  @media #{ $information-tablet } {
    overflow: hidden !important;
  }
}
